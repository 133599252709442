import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { NavBarCustom } from "./components/navbar";
import Footer from "./components/footer";

export function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <NavBarCustom />
        </Router>
      </div>
      <Router>
        <Footer />
      </Router>
    </div>
  );
}
