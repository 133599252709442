import { Routes, Route } from "react-router-dom";
import { WorkInProgress } from "../pages/workinprogress";
import { Home } from "../pages/home";

export const NavBarCustom = () => {
  return (
    <div className="content">
      <Routes>
        <Route path="/" element={<WorkInProgress />} />
        <Route path="/cv" element={<Home />} />
      </Routes>
    </div>
  );
};
