import { FaLinkedin, FaGithubSquare } from "react-icons/fa";
import { LanguageBtn } from "./utils/LanguageBtn";

const About = () => {
  return (
    <div className="footer-parent-class">
      <div className="footer-class">
      <div style={{position: 'absolute', 'left': '10px', bottom: '5px'}}>
            <div className="leading-tight text-sm text-grey-500">
                <span>pedroervalho@gmail.com</span>
            </div>
        </div>
        <div style={{position: 'absolute', 'right': '10px', bottom: '5px'}}>
            <LanguageBtn />
        </div>
      </div>
      <div className="footer-extra-class">
        <div className="footer-icon-class" style={{ marginLeft: "auto" }}>
          <a
            href="https://www.linkedin.com/in/pedro-martins-323118124/"
            target="_blank">
            <FaLinkedin />
          </a>
        </div>
        <div className="footer-icon-class">
          <a href="https://www.github.com/pedroervalho/" target="_blank">
            <FaGithubSquare />
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
