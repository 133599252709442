import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import { ProjectCard } from "./projects/ProjectCard";
import { SideBySide } from "./utils/SideBySide";

export const Projects = () => {
	const { t } = useTranslation();

    const [show, setShow] = useState<boolean>(false);

	return (
		<div>
			<div className="xs:p-1 sm:p-5">
                <div className="font-normal mt-4 mb-4">
                    <span className="text-4xl text-gray-500">{t('menu_projects')}</span> {" "}
                    <br className="xs:hidden visible" />
                    <span className="text-md text-gray-300">{t('menu_projects_label')}</span>
                </div>

                <div className={(!show ? 'projects-wrapper' : '')}>
                    <div className={'p-3 projects-parent ' + (!show ? 'phidden' : 'pvisible')}>
                        <div className="card-grid-container">
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={t('ptt_title')} body={t('ptt_body')}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("high_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2019 - " + t("present"), "", "text-sm"],
                                                    ]}
                                                />}
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={t('sipm_title')} body={t('sipm_body')}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("high_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018 - 2020", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={t('erp_title')} body={t('erp_body')}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Odoo, Python", "", "text-sm"],
                                                        ["2018 - 2019", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={t('fsign_title')} body={t('fsign_body')}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("high_role"), "", "text-sm"],
                                                        ["Javascript, Java App", "", "text-sm"],
                                                        ["2022 - " + t("present"), "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-6">
                                <ProjectCard favorite projectType={"enterprise"} title={t('pnfe_title')} body={t('pnfe_body')}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("high_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2022 - " + t("present"), "", "text-sm"],
                                                        ["www.pnfe.impic.pt/pnfe/login.xhtml", ""]
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-5">
                                <ProjectCard favorite projectType={"enterprise"} title={'ANOeGov'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["ReactJS, Typescript, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2023 - " + t("present"), "", "text-sm"],
                                                        ["www.youtube.com/watch?v=1wDplTUqIqg", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={'IFRRU - Porta de Entrada'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("high_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'IFRRU - Primeiro Direito'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'AtendimentoCovid19'}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2020", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'FutureDoc'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2020", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={'eFaturaGov'}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018 - 2020", "", "text-sm"],
                                                        ["https://efaturagov.com/", ""]
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard favorite projectType={"enterprise"} title={'SIIFRRU 2020'}
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2017 - 2018", "", "text-sm"],
                                                        ["https://www.youtube.com/watch?v=1wDplTUqIqg", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'Pedido de Apoio do COVID-19'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2020", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'FutureAttendance'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'FutureForms'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Angular", "", "text-sm"],
                                                        ["2018", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                            <div className="card-grid-item card-4">
                                <ProjectCard projectType={"enterprise"} title={'SGA/FutureMeeting'} 
                                             info={
                                                <SideBySide
                                                    labels={[
                                                        [t("low_role"), "", "text-sm"],
                                                        ["Primefaces, Spring, Hibernate, Java", "", "text-sm"],
                                                        ["2018", "", "text-sm"],
                                                    ]}
                                                />} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
				
                <div className="w-100 border-t-2 border-gray-200">
                    <button onClick={() => setShow(!show)} className="mt-2" style={{display: 'block', margin: 'auto'}}>
                        <div className="flex text text-gray-400 gap-2 items-center">
                            {!show ? <>
                                <FaPlusCircle /> <span>{t('more_projects')}</span>
                            </> : <>
                                <FaMinusCircle/> <span>{t('less_projects')}</span>
                            </>}
                        </div>
                    </button>
                </div>
			</div>
		</div>
	);
};
