import { useTranslation } from "react-i18next";

export const WorkInProgress = () => {
	const { t } = useTranslation();
    
	return (
		<div>
            <div className="work-in-progress">
                <div>
                    <span className="text-4xl">{t('workInProgressTitle')}</span> <br />
                    <span className="text-xl">{t('workInProgressSubTitle')}</span>
                </div>
            </div>
		</div>
	);
};
