import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const LanguageBtn = () => {
	const { i18n } = useTranslation();
    const location = useLocation();
	const navigate = useNavigate();
    const [searchParams] = useSearchParams();

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('l', lng); 
		navigate(`${location.pathname}?${newSearchParams.toString()}`);
	};

	const currentLanguage = i18n.language;

	return (
		<div>
			{currentLanguage !== "pt" && (
				<button onClick={() => changeLanguage("pt")}>
					<img
						src={require("../../images/en-flag-icon.png")}
						alt="EN"
						style={{ width: "30px" }}
					/>
				</button>
			)}
			{currentLanguage !== "en" && (
				<button onClick={() => changeLanguage("en")}>
					<img
						src={require("../../images/pt-flag-icon.png")}
						alt="PT"
						style={{ width: "30px" }}
					/>
				</button>
			)}
		</div>
	);
};
