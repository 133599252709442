
type Tuple = [string, string, string?];
type SideBySideProps = {
	labels: Tuple[];
};

export const SideBySide = ({labels}: SideBySideProps) => {
	return (
		<div>
			{labels.map((label, index) => (
				<div key={index} className={label[2]}>
					<span className="text-gray-400">{label[0]}</span> {" "}
					<span className={"text-gray-600"}>{label[1]}</span>
				</div>
			))}
		</div>
	);
};
