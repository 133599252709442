import { ReactElement } from "react";
import { FaStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

type ProjectCardProps = {
    title: string,
    body?: string,
    info?: ReactElement;
    favorite?: Boolean,
    projectType?: 'enterprise' | 'personal' | 'freelance',
}

type Card = {
	baseClass: string;
	titleClass: string;
	bodyClass: string;
	bodyLabelClass: string;
};

export const ProjectCard = ({title, body, info, favorite = false, projectType}: ProjectCardProps) => {

	const { t } = useTranslation();
    
    let baseClassDefault = "project-card rounded bg-white p-4 block";

	const favoriteCard = {
		baseClass: baseClassDefault + " w-full max-w-full border-t-4 border-b-2 border-l border-r border-neutral-400 border-t-neutral-700",
		titleClass: "mb-2 text-2xl font-medium tracking-tight text-black",
		bodyClass: "font-normal text-sm",
		bodyLabelClass: "text-black leading-tight",
	} as Card;

	const normalCard = {
		baseClass: baseClassDefault + " w-full max-w-full border border-gray-200",
		titleClass: "mb-2 text-2xl font-medium tracking-tight text-gray-900",
		bodyClass: "font-normal text-sm",
		bodyLabelClass: "text-gray-500 leading-tight",
	} as Card;

    let cardTier = favorite ? favoriteCard : normalCard;

	return (
		<div className={cardTier.baseClass} style={{position: 'relative'}}>
            {projectType &&
                <div style={{position: 'absolute', top: '0px', left: '5px'}} className="text-gray-400 text-[14px]">
                    {t(projectType + "_project")}
                </div>
            }
            {favorite &&
                <div style={{position: 'absolute', top: '5px', right: '5px'}}>
                    <FaStar size={17} color="#FFEA00"/>
                </div>
            }
			<h5 className={cardTier.titleClass}>
                {title}
            </h5>
			<div className={cardTier.bodyClass}>
				<div className={cardTier.bodyLabelClass}>
					<span>
                        {body}
					</span>
				</div>
				<div className="stick-to-bottom">
					{info}
				</div>
			</div>
		</div>
	);
};