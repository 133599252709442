// src/i18n.js or src/i18n.ts
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationPT from './locales/pt/translation.json';

const resources = {
  en: {
    translation: translationEN
  },
  pt: {
    translation: translationPT
  }
};

const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources, // Your translations
    lng: params.get('l') ?? 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation is not available
    interpolation: {
      escapeValue: false // React already escapes values to prevent XSS
    }
  });

export default i18n;