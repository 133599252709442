import { useTranslation } from 'react-i18next';

export const LandingHeader = () => {
    const { t } = useTranslation();

	return (
		<div className="landing-header">
			<div className="parallax">
                <div className="pt-[40vh]" style={{paddingLeft: '10vw'}}>
                    <div className='md:special-contrast font-medium'>
                        <h1 className="xxs:text-xl xs:text-2xl sm:text-3xl">{t('welcome')}</h1>
                        <h1 className="xxs:text-base sm:text-lg sm:text-xl">Fullstack developer</h1>
                    </div>
				</div>
			</div>
		</div>
	);
};
